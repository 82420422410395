<template>
  <div>
    <v-form>
      <p class="mb-3">
        Benzaldehyde is pictured below. Why can two molecules of benzaldehyde not react together via
        the aldol condensation reaction?
      </p>

      <p class="mb-5 pl-8">
        <img
          src="/img/assignments/benzaldehydeKekule.png"
          alt="Lewis structure of benzaldehyde"
          style="max-width: 173px"
        />
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_51LPPL7_Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'It does not contain an $\\alpha\\text{-hydrogen}$',
          value: 'alphaHydrogen',
        },
        {
          text: 'It does not contain an $\\alpha\\text{-carbon}$',
          value: 'alphaCarbon',
        },
        {
          text: 'It does not contain $\\beta\\text{-hydrogen}$',
          value: 'betaHydrogen',
        },
        {
          text: 'It does not contain a $\\beta\\text{-carbon}$',
          value: 'betaCarbon',
        },
      ],
    };
  },
};
</script>
